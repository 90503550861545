<template>
  <div>
    <v-alert
        v-if="error"
        dense
        type="error"
    >
      {{ error }}
    </v-alert>
    <v-card class="pt-3">
      <v-data-table
          :headers="headers"
          :items="styles"
          item-key="name"
          :search="search"
          :custom-filter="filter"
          :loading="loading.getListe"
          loading-text="Chargement... Merci de patienter"
          no-data-text="Aucune donnée..."
          no-results-text="Aucun résultat ne correspond à votre recherche."
      >

        <template v-slot:top>
          <v-toolbar
              flat
          >
            <v-toolbar-items>
              <v-text-field
                  v-model="search"
                  label="Rechercher un style"
                  filled
              ></v-text-field>
            </v-toolbar-items>
            <v-divider
                class="mx-4"
                inset
                vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-dialog
                v-model="dialog"
                max-width="500px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                >
                  Ajouter un style
                  <v-icon
                      right
                      dark
                  >
                    mdi-music-note-plus
                  </v-icon>
                </v-btn>
              </template>
              <v-card :loading="loading.edit">
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                          cols="12"
                      >
                        <v-text-field
                            v-model="editedItem.title"
                            label="Nom du style"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      filled
                      text
                      @click="close"
                  >
                    Annuler
                  </v-btn>
                  <v-btn
                      color="primary"
                      text
                      @click="save"
                  >
                    Sauvegarder
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card :loading="loading.delete">
                <v-card-title>Êtes-vous sûr de vouloir supprimer ce style ?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="closeDelete">Annuler</v-btn>
                  <v-btn color="primary" @click="deleteItemConfirm">Oui</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import api from '../services/API'

export default {
  name: 'Genres',
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      formTitle: 'Ajouter un style',
      search: '',
      headers: [
        {
          text: 'Style',
          align: 'start',
          sortable: true,
          value: 'title'
        },
        {
          text: 'Actions',
          align: 'end',
          sortable: false,
          value: 'actions'
        },
      ],
      loading: {
        getListe: false,
        edit: false,
        delete: false,
      },
      editedIndex: -1,
      editedItem: {
        title: '',
        id: null,
      },
      defaultItem: {
        title: '',
        id: null,
      },
      editedError: {
        title: false,
      },
      error: null,
    }
  },
  methods: {
    ...mapMutations({
      setStyles: 'SET_STYLES',
      addStyle: 'ADD_STYLE',
      editStyle: 'EDIT_STYLE',
      deleteStyle: 'DELETE_STYLE'
    }),
    filter(value, search, item) {
      if (search !== null && value !== null) {
        return item.title.includes(search)
      }
    },
    editItem (style) {
      this.editedItem = Object.assign({}, style)
      if (style) {
        this.formTitle = 'Modifier le style'
        this.editedIndex = this.styles.findIndex((item) => item === style)
      }
      this.dialog = true
    },

    deleteItem (style) {
      this.editedItem = Object.assign({}, style)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.loading.delete = true

      api.styles.deleteStyle(this.editedItem.id)
        .then(() => {
          this.deleteStyle(this.editedItem.id)
          this.loading.delete = false
          this.closeDelete()
        })
        .catch((error) => {
          this.error = error
          this.loading.delete = false
          this.closeDelete()
        })
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.formTitle = 'Ajouter un style'
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.error = null
      })
    },

    save () {
      this.error = null

      if (this.loading.edit) {
        return
      }

      if (this.editedIndex === -1) {
        this.loading.edit = true
        api.styles.postStyle(this.formatedItem)
            .then((response) => {
              if (response.data && response.data.style) {
                this.addStyle(response.data.style)
              }
              this.loading.edit = false
              this.close()
            })
            .catch((error) => {
              this.error = error
              this.loading.edit = false
            })
      } else {
        this.loading.edit = true
        api.styles.updateStyle(this.editedItem.id, this.formatedItem)
            .then((response) => {
              console.log(response)
              if (response.data && response.data.style) {
                this.editStyle(response.data.style)
              }
              this.loading.edit = false
              this.close()
            })
            .catch((error) => {
              this.error = error
              this.loading.edit = false
            })
      }
    },
  },
  computed: {
    ...mapState(['styles']),
    formatedItem() {
      return {
        title: this.editedItem.title
      }
    }
  },
  async mounted() {
    this.loading.getListe = true

    await api.styles.getStyles()
        .then((response) => {
          if (response.data.styles) {
            this.setStyles(response.data.styles)
          }
          this.loading.getListe = false
        })
        .catch((error) => {
          this.error = error.data
          this.loading.getListe = false
        })
  }
}
</script>
